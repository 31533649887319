import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`After installing postgres on Ubuntu 18 (via WSL) using `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.postgresql.org/download/linux/ubuntu/"
        }}>{`official documentation`}</MDXTag>{`, I run into multiple issues. This article might help someone who also wants to install postgres on WSL (same problems might be happening on native Ubuntu version). First follow instructions in official documentation, then follow steps below.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Change ownership of place where you'll be saving logs to `}<MDXTag name="inlineCode" components={components} parentName="p">{`postgres`}</MDXTag>{` user:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`sudo chown postgres /var/log/postgresql
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Next create symlinks for postgres configuration, not sure why this is not happening by default, perhaps you should setup your own configs, but in my case I just wanted to get it running because I was just following an online course:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`sudo ln -s /etc/postgresql/10/main/postgresql.conf  /var/lib/postgresql/10/main/postgresql.conf
sudo ln -s /etc/postgresql/10/main/conf.d  /var/lib/postgresql/10/main/conf.d
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Finally start server as postgres user:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`sudo -u postgres /usr/lib/postgresql/10/bin/pg_ctl -D /var/lib/postgresql/10/main -l /var/log/postgresql/logfile start
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`After that, in order to start working with Postgres from command line I used following command: `}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`sudo -u postgres psql postgres
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Good luck :)`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "How to install Postgres on Ubuntu 18 (via WSL)",
  "date": "2019-06-02T12:00:00.000Z",
  "overview": "Dry instructions for installing & running Postgres on Ubuntu 18 (via WSL)",
  "type": "post"
};
    